<template>

  <div class="createTariff">
    <mini-loader v-if="load" />
    <div class="card">
      <div class="d-flex justify-content-between mb-4">
        <div>
          <label for="exampleInputEmail1">Наименование</label>
          <input type="text" v-model="newItem.title" class="form-control" :class="{'is-invalid': validateErrors.title}" placeholder="Наименование">
          <div class="invalid-feedback" v-for="value in validateErrors.title"> {{value}} </div>
        </div>
        <div>
          <label for="exampleInputEmail1">Описание</label>
          <input type="text" v-model="newItem.description"  class="form-control" :class="{'is-invalid': validateErrors.description}" placeholder="Описание">
          <div class="invalid-feedback" v-for="value in validateErrors.description"> {{value}} </div>
        </div>
        <div>
          <label for="exampleInputEmail1">Тип груза</label>
          <a-select default-value="active" v-model="newItem.type_of_cargo_id" class="w-100" :class="{'is-invalid': validateErrors.type_of_cargo_id}" allowClear="true">
            <a-select-option v-for="(value, index) in typeOfCargo" :key="value.id" :value="value.id" >
              <span>{{value.title}}</span>
            </a-select-option>
          </a-select>
          <div class="invalid-feedback" v-for="value in validateErrors.type_of_cargo_id"> {{value}} </div>
        </div>
        <div>
          <label for="exampleInputEmail1">Тип тарифа</label>
          <a-select default-value="active" :allowClear="true"  v-model="newItem.type" class="w-100" :class="{'is-invalid': validateErrors.type}"  @change="changeTypeTariff">
            <a-select-option v-for="(value, index) in type" :key="value.value" :value="value.value" >
              <span v-if="value.value == 'international'" style="color: #689F38">{{value.title}}</span>
              <span v-else style="color: #4792E2">{{value.title}}</span>
            </a-select-option>
          </a-select>
          <div class="invalid-feedback" v-for="value in validateErrors.client_type"> {{value}} </div>
        </div>
        <div>
          <label for="exampleInputEmail1">Статус</label>
          <a-select default-value="active" :allowClear="true" v-model="newItem.status" class="w-100" :class="{'is-invalid': validateErrors.status}">
            <a-select-option v-for="(value, index) in status" :key="value.id" :value="value.value">
              {{value.title}}
            </a-select-option>
          </a-select>
          <div class="invalid-feedback" v-for="value in validateErrors.status"> {{value}} </div>
        </div>
        <div>
          <label for="exampleInputEmail1">Филиал</label>
          <a-select default-value="active" :allowClear="true"  v-model="newItem.branchoffice_id" class="w-100" :class="{'is-invalid': validateErrors.branchoffice_id}" @change="changeBranchOffice">
            <a-select-option v-for="(value, index) in branches" :key="value.id" :value="value.id" >
              {{value.title}} <span v-if="value.type == 'international'" style="color: #689F38">(Международный)</span>
            </a-select-option>
          </a-select>
          <div class="invalid-feedback" v-for="value in validateErrors.branchoffice_id"> {{value}} </div>
        </div>
        <div>
          <label for="exampleInputEmail1">Тип услуги</label>
          <a-select default-value="active" v-model="newItem.type_service_id" class="w-100" :class="{'is-invalid': validateErrors.type_service_id}" >
            <a-select-option v-for="(value, index) in typeOfService" :key="value.id" :value="value.id" >
              <span style="color: blue" v-if="value.service_type == 'usual'">{{value.title}}</span>
              <span style="color: green" v-else-if="value.service_type == 'boomerang'">{{value.title}}</span>
              <span v-else>{{value.title}}</span>
            </a-select-option>
          </a-select>
          <div class="invalid-feedback" v-for="value in validateErrors.type_service_id"> {{value}} </div>
        </div>
        <div>
          <label for="exampleInputEmail1">Тип цены</label>
          <a-select default-value="active" v-model="newItem.price_type" class="w-100" :class="{'is-invalid': validateErrors.price_type}" @change="changePriceType">
            <a-select-option v-for="(value, index) in priceType" :key="value.value" :value="value.value" >
              {{value.title}}
            </a-select-option>
          </a-select>
          <div class="invalid-feedback" v-for="value in validateErrors.price_type"> {{value}} </div>
        </div>
        <div>
          <label for="exampleInputEmail1">Тип клиента</label>
          <a-select default-value="active" :allowClear="true" v-model="newItem.client_type" class="w-100" :class="{'is-invalid': validateErrors.client_type}" @change="changeClientType">
            <a-select-option v-for="(value, index) in clientType" :key="value.value" :value="value.value" >
              {{value.title}}
            </a-select-option>
          </a-select>
          <div class="invalid-feedback" v-for="value in validateErrors.client_type"> {{value}} </div>
        </div>
      </div>

      <!-- Linear price -->
      <div class="d-flex justify-content-between mb-2 align-items-end" v-if="linearPrice" v-for="(value, index) in newItem.prices">
        <div>
          <label for="exampleInputEmail1">Базовая цена</label>
          <input type="text" v-model="newItem.prices[index].basic_price" class="form-control" :class="{'is-invalid': validateErrors['prices.'+ index +'.basic_price']}" placeholder="Наименование">
          <!-- <div class="invalid-feedback" v-for="value in validateErrors['prices.'+ index +'.basic_price']"> {{value}} </div> -->
        </div>
        <div>
          <label for="exampleInputEmail1">Кг включено</label>
          <input type="text" v-model="newItem.prices[index].included_weight" class="form-control" :class="{'is-invalid': validateErrors['prices.'+ index +'.included_weight']}" placeholder="Наименование">
          <!-- <div class="invalid-feedback" v-for="value in validateErrors['prices.'+ index +'.included_weight']"> {{value}} </div> -->
        </div>
        <div>
          <label for="exampleInputEmail1">Км включено</label>
          <input type="text" v-model="newItem.prices[index].included_km" class="form-control" :class="{'is-invalid': validateErrors['prices.'+ index +'.included_km']}" placeholder="Наименование">
          <!-- <div class="invalid-feedback" v-for="value in validateErrors['prices.'+ index +'.included_km']"> {{value}} </div> -->
        </div>
        <div>
          <label for="exampleInputEmail1">Цена за кг</label>
          <input type="text" v-model="newItem.prices[index].price_per_weight" class="form-control" :class="{'is-invalid': validateErrors['prices.'+ index +'.price_per_weight']}" placeholder="Наименование">
          <!-- <div class="invalid-feedback" v-for="value in validateErrors['prices.'+ index +'.price_per_weight']"> {{value}} </div> -->
        </div>
        <div>
          <label for="exampleInputEmail1">Вес</label>
          <input type="text" v-model="newItem.prices[index].weight" class="form-control" :class="{'is-invalid': validateErrors['prices.'+ index +'.weight']}" placeholder="Наименование">
          <!-- <div class="invalid-feedback" v-for="value in validateErrors['prices.'+ index +'.weight']"> {{value}} </div> -->
        </div>
        <div>
          <label for="exampleInputEmail1">Цена за км</label>
          <input type="text" v-model="newItem.prices[index].price_per_km" class="form-control" :class="{'is-invalid': validateErrors['prices.'+ index +'.price_per_km']}" placeholder="Наименование">
          <!-- <div class="invalid-feedback" v-for="value in validateErrors['prices.'+ index +'.price_per_km']"> {{value}} </div> -->
        </div>
        <div>
          <label for="exampleInputEmail1">Базовая цена за возврат</label>
          <input type="text" v-model="newItem.prices[index].basic_price_per_return" class="form-control" :class="{'is-invalid': validateErrors['prices.'+ index +'.basic_price_per_return']}" placeholder="Наименование">
          <!-- <div class="invalid-feedback" v-for="value in validateErrors['prices.'+ index +'.basic_price_per_return']"> {{value}} </div> -->
        </div>
        <div>
          <label for="exampleInputEmail1">Цена за возврат за кг</label>
          <input type="text" v-model="newItem.prices[index].price_per_return_per_weight" class="form-control" :class="{'is-invalid': validateErrors['prices.'+ index +'.price_per_return_per_weight']}" placeholder="Наименование">
          <!-- <div class="invalid-feedback" v-for="value in validateErrors['prices.'+ index +'.price_per_return_per_weight']"> {{value}} </div> -->
        </div>

        <div class="text-danger p-2" @click="clearLinearPrice(index)" style="cursor: pointer">
          <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-x-lg" viewBox="0 0 16 16">
            <path d="M1.293 1.293a1 1 0 0 1 1.414 0L8 6.586l5.293-5.293a1 1 0 1 1 1.414 1.414L9.414 8l5.293 5.293a1 1 0 0 1-1.414 1.414L8 9.414l-5.293 5.293a1 1 0 0 1-1.414-1.414L6.586 8 1.293 2.707a1 1 0 0 1 0-1.414z"/>
          </svg>
        </div>
      </div>

      <!-- Unlinear prices -->
      <div class="d-flex justify-content-between mb-2 align-items-end" v-if="unlinearPrice" v-for="(value, index) in newItem.unlinear_price">
        <div>
          <label for="exampleInputEmail1">Базовая цена</label>
          <input type="text" v-model="newItem.unlinear_price[index].basic_price" class="form-control" :class="{'is-invalid': validateErrors['unlinear_price.'+ index +'.basic_price']}" placeholder="Наименование">
          <!-- <div class="invalid-feedback" v-for="value in validateErrors['unlinear_price.'+ index +'.basic_price']"> {{value}} </div> -->
        </div>
        <div>
          <label for="exampleInputEmail1">Режим сравнения</label>
          <a-select default-value="active" v-model="newItem.unlinear_price[index].mode" class="w-100" :class="{'is-invalid': validateErrors['unlinear_price.'+ index +'.mode']}">
            <a-select-option v-for="value in mode" :key="value.id" :value="value.value">
              {{value.title}}
            </a-select-option>
          </a-select>
          <!-- <div class="invalid-feedback" v-for="value in validateErrors['unlinear_price.'+ index +'.mode']"> {{value}} </div> -->
        </div>

        <div v-if="newItem.unlinear_price[index].mode != 'only_km'">
          <label for="exampleInputEmail1">Кг от</label>
          <input type="text" v-model="newItem.unlinear_price[index].weight_from" class="form-control" :class="{'is-invalid': validateErrors['unlinear_price.'+ index +'.weight_from']}" placeholder="Наименование">
          <!-- <div class="invalid-feedback" v-for="value in validateErrors['unlinear_price.'+ index +'.weight_from']"> {{value}} </div> -->
        </div>
        <div v-if="newItem.unlinear_price[index].mode != 'only_km'">
          <label for="exampleInputEmail1">Кг до</label>
          <input type="text" v-model="newItem.unlinear_price[index].weight_to" class="form-control" :class="{'is-invalid': validateErrors['unlinear_price.'+ index +'.weight_to']}" placeholder="Наименование">
          <!-- <div class="invalid-feedback" v-for="value in validateErrors['unlinear_price.'+ index +'.weight_to']"> {{value}} </div> -->
        </div>
        <div v-if="newItem.unlinear_price[index].mode != 'only_kg'">
          <label for="exampleInputEmail1">Км от</label>
          <input type="text" v-model="newItem.unlinear_price[index].km_from" class="form-control" :class="{'is-invalid': validateErrors['unlinear_price.'+ index +'.km_from']}" placeholder="Наименование">
          <!-- <div class="invalid-feedback" v-for="value in validateErrors['unlinear_price.'+ index +'.km_from']"> {{value}} </div> -->
        </div>
        <div v-if="newItem.unlinear_price[index].mode != 'only_kg'">
          <label for="exampleInputEmail1">Км до</label>
          <input type="text" v-model="newItem.unlinear_price[index].km_to" class="form-control" :class="{'is-invalid': validateErrors['unlinear_price.'+ index +'.km_to']}" placeholder="Наименование">
          <!-- <div class="invalid-feedback" v-for="value in validateErrors['unlinear_price.'+ index +'.km_to']"> {{value}} </div> -->
        </div>
        <div v-if="newItem.unlinear_price[index].mode == 'only_km'">
          <label for="exampleInputEmail1">Кг включено</label>
          <input type="text" v-model="newItem.unlinear_price[index].included_weight" class="form-control" :class="{'is-invalid': validateErrors['unlinear_price.'+ index +'.included_weight']}" placeholder="Наименование">
          <!-- <div class="invalid-feedback" v-for="value in validateErrors['unlinear_price.'+ index +'.included_weight']"> {{value}} </div> -->
        </div>
        <div v-if="newItem.unlinear_price[index].mode == 'only_kg'">
          <label for="exampleInputEmail1">Км включено</label>
          <input type="text" v-model="newItem.unlinear_price[index].included_km" class="form-control" :class="{'is-invalid': validateErrors['unlinear_price.'+ index +'.included_km']}" placeholder="Наименование">
          <!-- <div class="invalid-feedback" v-for="value in validateErrors['unlinear_price.'+ index +'.included_km']"> {{value}} </div> -->
        </div>

        <div>
          <label for="exampleInputEmail1">Цена за кг</label>
          <input type="text" v-model="newItem.unlinear_price[index].price_per_weight" class="form-control" :class="{'is-invalid': validateErrors['unlinear_price.'+ index +'.price_per_weight']}" placeholder="Наименование">
          <!-- <div class="invalid-feedback" v-for="value in validateErrors['unlinear_price.'+ index +'.price_per_weight']"> {{value}} </div> -->
        </div>
        <div>
          <label for="exampleInputEmail1">Вес</label>
          <input type="text" v-model="newItem.unlinear_price[index].weight" class="form-control" :class="{'is-invalid': validateErrors['unlinear_price.'+ index +'.weight']}" placeholder="Наименование">
          <!-- <div class="invalid-feedback" v-for="value in validateErrors['unlinear_price.'+ index +'.weight']"> {{value}} </div> -->
        </div>
        <div>
          <label for="exampleInputEmail1">Цена за км</label>
          <input type="text" v-model="newItem.unlinear_price[index].price_per_km" class="form-control" :class="{'is-invalid': validateErrors['unlinear_price.'+ index +'.price_per_km']}" placeholder="Наименование">
          <!-- <div class="invalid-feedback" v-for="value in validateErrors['unlinear_price.'+ index +'.price_per_km']"> {{value}} </div> -->
        </div>
        <div>
          <label for="exampleInputEmail1">Базовая цена за возврат</label>
          <input type="text" v-model="newItem.unlinear_price[index].basic_price_per_return" class="form-control" :class="{'is-invalid': validateErrors['unlinear_price.'+ index +'.basic_price_per_return']}" placeholder="Наименование">
          <!-- <div class="invalid-feedback" v-for="value in validateErrors['unlinear_price.'+ index +'.basic_price_per_return']"> {{value}} </div> -->
        </div>
        <div>
          <label for="exampleInputEmail1">Цена за возврат за кг</label>
          <input type="text" v-model="newItem.unlinear_price[index].price_per_return_per_weight" class="form-control" :class="{'is-invalid': validateErrors['unlinear_price.'+ index +'.price_per_return_per_weight']}" placeholder="Наименование">
          <!-- <div class="invalid-feedback" v-for="value in validateErrors['unlinear_price.'+ index +'.price_per_return_per_weight']"> {{value}} </div> -->
        </div>
        <div class="text-danger p-2" @click="clearUnlinearPrice(index)" style="cursor: pointer">
          <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-x-lg" viewBox="0 0 16 16">
            <path d="M1.293 1.293a1 1 0 0 1 1.414 0L8 6.586l5.293-5.293a1 1 0 1 1 1.414 1.414L9.414 8l5.293 5.293a1 1 0 0 1-1.414 1.414L8 9.414l-5.293 5.293a1 1 0 0 1-1.414-1.414L6.586 8 1.293 2.707a1 1 0 0 1 0-1.414z"/>
          </svg>
        </div>
      </div>

      <button class="btn btn-success m-auto" v-if="addPrice" @click="addPriceData">Добавить цену</button>

    </div>

    <div class="rules-content">
      <div class="rules box-shadow" @click="getRule(newItem.rules)">
        <span>Правила (0)</span>
        <span v-if="boolAddRules(newItem.rules)">
          <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-chevron-down" viewBox="0 0 16 16">
            <path fill-rule="evenodd" d="M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z"/>
          </svg>
        </span>
        <span v-else>
          <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-chevron-up" viewBox="0 0 16 16">
            <path fill-rule="evenodd" d="M7.646 4.646a.5.5 0 0 1 .708 0l6 6a.5.5 0 0 1-.708.708L8 5.707l-5.646 5.647a.5.5 0 0 1-.708-.708l6-6z"/>
          </svg>
        </span>
      </div>

      <div class="view-show" v-if="boolAddRules(newItem.rules)">
        <div class="border-left-yes">
          <div class="rules-add-conditions box-shadow" style="margin-left: 8px">
            <span class="d-100 d-h" v-if="newItem.rules[0].boolAndOr == 'and'" @click="boolRuleAndOr(newItem.rules)">И</span>
            <span class="d-100 d-h" v-if="newItem.rules[0].boolAndOr == 'or'" @click="boolRuleAndOr(newItem.rules)">ИЛИ</span>
             <span class="d-150 d-flex align-items-center div-margin-5">Условия
               <a-form-item>
                <a-switch v-model="newItem.rules[0].conditionsBool" v-decorator="['switch', { valuePropName: 'checked' }]" @change="changeItem(newItem.rules)" />
              </a-form-item>
             </span>
             <span class="d-200 d-h" v-if="newItem.rules[0].addConditionsItem" @click="addCondition(newItem.rules)">
               Добавить условие
             </span>
             <span class="d-200 d-h" v-if="newItem.rules[0].addRulesItem" @click="addRule(newItem.rules)">
               Добавить группу правил
             </span>
          </div>
        </div>

        <div class="conditions border-with-before" style="margin-left: 30px" v-if="hasConditions(newItem.rules) && newItem.rules[0].addConditionsItem">
          <div class="d-flex width-100 align-items-end" v-for="(condition, conditionIndex) in newItem.rules[1].conditions" :key="conditionIndex">
            <div class="col-sm-2">
              <label for="exampleInputEmail1">Выберите условие</label>
              <a-form-item>
                <a-select v-model="condition.condition_name"  placeholder="Выберите условие" @change="changeConditons(condition, conditionIndex)">
                  <a-select-option v-for="(value, index) in conditions" :key="value.value" :value="value.value">
                    {{value.title}}
                  </a-select-option>
                </a-select>
              </a-form-item>
              <div class="invalid-feedback" v-for="value in validateErrors.price_type"> {{value}} </div>
            </div>

            <div class="col-sm-2"  v-if="condition.condition_name != ''" >
              <label for="">Значение</label>
              <template>
                <a-form-item
                  :validate-status = "condition.errorValue"
                >
                  <a-select
                    show-search
                    v-model="condition.value"
                    placeholder="Ваберите значение"
                    style="width: 300px"
                    option-filter-prop="children"
                    :filter-option="filterOption"
                    @blur="blurConditionValue(condition)"
                  >
                    <a-select-option v-for="(value, index) in conditionValue" :value="value.value">
                      {{value.title}}
                    </a-select-option>
                  </a-select>
                </a-form-item>
              </template>
            </div>

            <div class="col-sm-2"  v-if="condition.condition_name == 'country_from'">
              <label for="">Страна отправки</label>
              <template>
                <a-form-item
                  :validate-status = "condition.errorConditionItem"
                >
                  <a-select
                    show-search
                    v-model="condition.country_from"
                    placeholder="Выберите страну"
                    style="width: 300px"
                    option-filter-prop="children"
                    :filter-option="filterOption"
                    @search="searchCountriesForConditions"
                    @blur="blurConditionItem(condition)"
                  >
                    <a-select-option v-for="(value, index) in countries" :value="value.id">
                      {{value.title}}
                    </a-select-option>
                  </a-select>
                </a-form-item>
              </template>
            </div>
            <div class="col-sm-2"  v-if="condition.condition_name == 'country_to'">
              <label for="">Страна доставки</label>
              <template>
                <a-form-item
                  :validate-status = "condition.errorConditionItem"
                >
                  <a-select
                    show-search
                    v-model="condition.country_to"
                    placeholder="Выберите страну"
                    style="width: 300px"
                    option-filter-prop="children"
                    :filter-option="filterOption"
                    @search="searchCountriesForConditions"
                    @blur="blurConditionItem(condition)"
                  >
                    <a-select-option v-for="(value, index) in countries" :value="value.id">
                      {{value.title}}
                    </a-select-option>
                  </a-select>
                </a-form-item>
              </template>
            </div>

            <div class="col-sm-2"  v-if="condition.condition_name == 'region_from'">
              <label for="">Область отправки</label>
              <template>
                <a-form-item
                  :validate-status = "condition.errorConditionItem"
                >
                  <a-select
                    show-search
                    v-model="condition.region_from"
                    placeholder="Выберите область"
                    style="width: 300px"
                    option-filter-prop="children"
                    :filter-option="filterOption"
                    @blur="blurConditionItem(condition)"
                  >
                    <a-select-option v-for="(value, index) in regions" :value="value.id">
                      {{value.title}}
                    </a-select-option>
                  </a-select>
                </a-form-item>
              </template>
            </div>
            <div class="col-sm-2"  v-if="condition.condition_name == 'region_to'">
              <label for="">Область доставки</label>
              <template>
                <a-form-item
                  :validate-status = "condition.errorConditionItem"
                >
                  <a-select
                    show-search
                    v-model="condition.region_to"
                    placeholder="Выберите область"
                    style="width: 300px"
                    option-filter-prop="children"
                    :filter-option="filterOption"
                    @blur="blurConditionItem(condition)"
                  >
                    <a-select-option v-for="(value, index) in regions" :valuetitle="value.title" :value="value.id">
                      {{value.title}}
                    </a-select-option>
                  </a-select>
                </a-form-item>
              </template>
            </div>

            <div class="col-sm-2"  v-if="condition.condition_name == 'city_from'">
              <label for="">Район отправки</label>
              <template>
                <a-form-item
                  :validate-status = "condition.errorConditionItem"
                >
                  <a-select
                    show-search
                    v-model="condition.city_from"
                    placeholder="Выберите район"
                    style="width: 300px"
                    option-filter-prop="children"
                    :filter-option="filterOption"
                    @blur="blurConditionItem(condition)"
                  >
                    <a-select-option v-for="(value, index) in cities" :value="value.id">
                      {{value.title}}
                    </a-select-option>
                  </a-select>
                </a-form-item>
              </template>
            </div>
            <div class="col-sm-2"  v-if="condition.condition_name == 'city_to'">
              <label for="">Район доставки</label>
              <template>
                <a-form-item
                  :validate-status = "condition.errorConditionItem"
                >
                  <a-select
                    show-search
                    v-model="condition.city_to"
                    placeholder="Выберите район"
                    style="width: 300px"
                    option-filter-prop="children"
                    :filter-option="filterOption"
                    @blur="blurConditionItem(condition)"
                  >
                    <a-select-option v-for="(value, index) in cities" :value="value.id">
                      {{value.title}}
                    </a-select-option>
                  </a-select>
                </a-form-item>
              </template>
            </div>

            <div class="col-sm-2"  v-if="condition.condition_name == 'branchoffice_from'">
              <label for="">Склад для забора</label>
              <template>
                <a-form-item
                  :validate-status = "condition.errorConditionItem"
                >
                  <a-select
                    show-search
                    v-model="condition.branchoffice_from"
                    placeholder="Выберите офис"
                    style="width: 300px"
                    option-filter-prop="children"
                    :filter-option="filterOption"
                    @blur="blurConditionItem(condition)"
                  >
                    <a-select-option v-for="(value, index) in branches" :value="value.id">
                      {{value.title}}
                    </a-select-option>
                  </a-select>
                </a-form-item>
              </template>
            </div>
            <div class="col-sm-2"  v-if="condition.condition_name == 'branchoffice_to'">
              <label for="">Конечный склад</label>
              <template>
                <a-form-item
                  :validate-status = "condition.errorConditionItem"
                >
                  <a-select
                    show-search
                    v-model="condition.branchoffice_to"
                    placeholder="Выберите офис"
                    style="width: 300px"
                    option-filter-prop="children"
                    :filter-option="filterOption"
                    @blur="blurConditionItem(condition)"
                  >
                    <a-select-option v-for="(value, index) in branches" :value="value.id">
                      {{value.title}}
                    </a-select-option>
                  </a-select>
                </a-form-item>
              </template>
            </div>

            <div class="col-sm-2"  v-if="condition.condition_name == 'client'">
              <label for="">Клиент</label>
              <template>
                <a-form-item
                  :validate-status = "condition.errorConditionItem"
                >
                  <a-select
                    show-search
                    v-model="condition.client"
                    placeholder="Выберите клиента"
                    style="width: 300px"
                    option-filter-prop="children"
                    :filter-option="filterOption"
                    @blur="blurConditionItem(condition)"
                  >
                    <a-select-option v-for="(value, index) in clients" :value="value.id">
                      {{value.name}}
                    </a-select-option>
                  </a-select>
                </a-form-item>
              </template>
            </div>

            <section class="trash-conditions box-shadow" @click="trashCondition(newItem.rules[1].conditions, conditionIndex)" v-if="condition.condition_name != ''">
              <svg xmlns="http://www.w3.org/2000/svg" width="25" height="25" fill="currentColor" class="bi bi-dash" viewBox="0 0 16 16">
                <path fill="#000" d="M4 8a.5.5 0 0 1 .5-.5h7a.5.5 0 0 1 0 1h-7A.5.5 0 0 1 4 8z"/>
              </svg>
            </section>
          </div>
        </div>

        <div v-if="newItem.rules.length > 1 && !newItem.rules[0].conditionsBool">
          <div v-for="(rule, index) in newItem.rules">
            <Rules v-if="index != 0 && rule.rules"
              :border="borderLeftYes(newItem.rules, index)"
              :rules="rule.rules"
              :index="index"
              :branches="branches"
              :typeOfService="typeOfService"
              :parentRule="newItem.rules"
              :countries="countries"
              :regions="regions"
              :cities="cities"
              :clients="clients"
              :conditionValue="conditionValue"
              :conditions="conditions"
              :errorCount="errorCount"
            />
          </div>
        </div>

      </div>
    </div>
    <div class="mt-5 mb-5" style="display: flex; justify-content: flex-end">
      <button class="btn btn-success" @click="updateItem">
        Изменить
      </button>
    </div>
  </div>
</template>

<script>
import axios from 'axios'
import Rules from '@/views/tariffs/components/Rules'

export default {
  name: 'TariffCreate',
  components:{
    Rules
  },
  data() {
    return {
      rulesForEdit: [],
      newItem: {
        title: '',
        description: '',
        branchoffice_id: '',
      	type_service_id: '',
      	type_of_cargo_id: '',
        type: 'international',
        status: 'active',
        price_type: 'linear',
        client_type: '',
        prices: [],
        unlinear_price: [],
        rules: [
          {
            showRules: false,
            addRulesItem: true,
            addConditionsItem: false,
            conditionsBool: false,
            boolAndOr: 'and'
          }
        ]
      },
      validateErrors: [],
      errorCount: 0,

      typeOfCargo: [],
      type: [
        {
          title: 'Международный',
          value: 'international'
        },
        {
          title: 'Местный',
          value: 'local'
        }
      ],
      status: [
        {
          title: 'Активный',
          value: 'active'
        },
        {
          title: 'Не активный',
          value: 'inactive'
        }
      ],
      priceType: [
        {
          title: 'Линейный',
          value: 'linear'
        },
        {
          title: 'Не линейный',
          value: 'unlinear'
        }
      ],
      clientType: [
        {
          title: 'Физ. лицо',
          value: 'individual'
        },
        {
          title: 'Юр. лицо',
          value: 'entity'
        }
      ],
      typeOfServiceAll: [],
      typeOfService: [],
      mode: [
        {
          title: 'Только кг',
          value: 'only_kg'
        },
        {
          title: 'Только км',
          value: 'only_km'
        },
        {
          title: 'Кг и км',
          value: 'kg_and_km'
        },
        {
          title: 'Кг или км',
          value: 'kg_or_km'
        },
      ],
      countries: [{title: "Узбекистан", id: 192},{title: "Россия", id: 1}, {title: "Казахстан", id: 82}],
      regions: [],
      cities: [],
      branches: [],
      clients: [],

      conditions:[
        {
          title: "Страна отправки",
          value: 'country_from'
        },
        {
          title: "Страна доставки",
          value: 'country_to'
        },
        {
          title: "Область отправки",
          value: 'region_from'
        },
        {
          title: "Область доставки",
          value: 'region_to'
        },
        {
          title: "Район отправки",
          value: 'city_from'
        },
        {
          title: "Район доставки",
          value: 'city_to'
        },
        {
          title: "Склад для забора посылки",
          value: 'branchoffice_from'
        },
        {
          title: "Конечный склад",
          value: 'branchoffice_to'
        },
        {
          title: "Клиент",
          value: 'client'
        },
      ],
      conditionValue: [
        {
          title: "Равно",
          value: "equally"
        },
        {
          title: "Не равно",
          value: "unequally"
        }
      ],

      addPrice: true,
      linearPrice: true,
      unlinearPrice: false,
      load: true,

      cycle: 1,

      dataFilter: {
        countries: [192],
        regions: [],
        cities: []
      },

    }
  },
  mounted() {

    axios.get('/tariffs/'+ this.$route.params.id).then((response) => {
      if(response.status == 200){
        this.newItem = response.data;
        if(this.newItem.price_type == 'linear'){
          this.linearPrice = true;
          this.unlinearPrice = false;
        }else{
          this.linearPrice = false;
          this.unlinearPrice = true;
        }
        if(this.newItem.rules.length){
          this.getRulesWithConditions(this.newItem.rules[0], this.rulesForEdit);
          this.newItem.rules = this.rulesForEdit;
        }else{
          let rule = {
              showRules: false,
              addRulesItem: true,
              addConditionsItem: false,
              conditionsBool: false,
              boolAndOr: 'and'

          };
          this.newItem.rules.push(rule);
        }
      }
    }).catch((error)  =>  {
      if(error.response.status == 404){
        this.$message.error(
          error.response.data.error,
          3
        );
      }else if(error.response.status == 500){
        this.$message.error(
          'Ошибка сервера',
          3
        );
      }
    });

    this.getBranches();
    this.getRegions();
    this.getCities();
    this.getClients();
    this.getTypesOfCargo();
    this.getServiceTypes();
  },
  methods: {
    getRulesWithConditions(rules, rulesForEdit){
      let rule = {
          showRules: false,
          addRulesItem: true,
          addConditionsItem: false,
          conditionsBool: false,
          boolAndOr: 'and'
      };
      rulesForEdit.push(rule);
      if(rules.bool_and_or){
        rulesForEdit[0].boolAndOr = rules.bool_and_or;
        rulesForEdit[0].showRules = true;
      }
      if(rules.conditions.length){
        let conditions = rules.conditions;
        rulesForEdit[0].addConditionsItem = true;
        rulesForEdit[0].conditionsBool = true;
        rulesForEdit[0].addRulesItem = false;
        rulesForEdit.push({conditions});
      }else if(rules.rules.length){
        // console.log(rules.rules.length)
        // console.log(rules.rules[0].rules.length)
        // console.log(rules.rules[0].rules[0].rules.length)
        for(let i=0; i<rules.rules.length; i++){
          let rulesNew = {rules: []};
          rulesForEdit.push(rulesNew);
          this.getRulesWithConditions(rules.rules[i], rulesForEdit[i+1].rules);
        }

      }
    },
    updateItem(){
      this.load = true;
      this.errorCount = 0;
      this.validateConditions(this.newItem.rules, this.errorCount);
      if(this.errorCount == 0){
        axios.put('/tariffs/'+this.newItem.id, this.newItem).then(response => {
          if(response.status == 200){
            this.validateErrors = [];
            this.$router.push('/tariffs');
            this.$message.success(
              'Успешно изменено',
              3
            );
          }
        }).catch(error => {
          if(error.response.status == 422){
            this.validateErrors = error.response.data.errors
            this.$message.error(
              'Проверьте правильность заполнения данных',
              3
            );
          }else if(error.response.status == 404){
            this.$message.error(
              error.response.data.error,
              3
            );
          }
        }).finally(() => {
          this.load = false;
        });
      }else{
        this.load = false;
        this.$message.error(
          'Проверьте правильность заполнения условий',
          3
        );
      }

    },
    validateConditions(rules){
      if(rules.length > 1){
        for(let i=1; i<rules.length; i++){
          if(rules[0].conditionsBool && rules[i].conditions){
            for(let j=0; j<rules[i].conditions.length; j++){
              if(rules[i].conditions[j].condition_name){
                if(!rules[i].conditions[j].value){
                  rules[i].conditions[j].errorValue = 'error';

                  this.errorCount++;
                  this.$forceUpdate();
                }
                if(!rules[i].conditions[j][rules[i].conditions[j].condition_name]){
                  rules[i].conditions[j].errorConditionItem = 'error';
                  this.errorCount++;
                  this.$forceUpdate();
                }
              }
            }
          }else if(rules[0].addRulesItem && rules[i].rules){

            this.validateConditions(rules[i].rules)
          }
        }
      }
    },
    blurConditionValue(value){
      if(!value.value){
        value.errorValue = 'error';
        this.$forceUpdate();
      }else{
        value.errorValue = '';
        this.$forceUpdate();
      }
    },
    blurConditionItem(value){
      if(!value[value.condition_name]){
        value.errorConditionItem = 'error';
        this.$forceUpdate();
      }else{
        value.errorConditionItem = '';
        this.$forceUpdate();
      }
    },
    trashCondition(conditions, conditionIndex){
      conditions.splice(conditionIndex, 1);
    },
    changeConditons(condition, conditionIndex){

    },
    filterOption(input, option) {
      return (
        option.componentOptions.children[0].text.toLowerCase().indexOf(input.toLowerCase()) >= 0
      );
    },
    searchCountriesForConditions(value){
      axios.get('/international/countries/autocomplete/search', {params: {query: value}}).then(response => {
        if(response.status == 200){
          this.countries = response.data;
        }else if(response.status == 204){
          this.$message.error(
                'Нет соответствующих стран',
                3,
          );
        }
      }).catch(error => {
        if(error.response.status == 204){
          this.$message.error(
                'Нет соответствующих стран',
                3,
          );
        }else if(error.response.status == 500){
          this.$message.error(
                'Ошибка сервера',
                3,
          );
        }
      })
    },
    searchRegionsForConditions(value){
      axios.post('/regions/autocomplete/search/with/paginate', this.dataFilter, {params: {query: value}}).then(response => {
        if(response.status == 200){
          this.regions = response.data.data;
        }else if(response.status == 204){
          this.$message.error(
                'Нет соответствующих стран',
                3,
          );
        }
      }).catch(error => {
        if(error.response.status == 204){
          this.$message.error(
                'Нет соответствующих стран',
                3,
          );
        }else if(error.response.status == 500){
          this.$message.error(
                'Ошибка сервера',
                3,
          );
        }
      })
    },

    changeTypeTariff(){
        this.newItem.type_service_id = '';
        this.typeOfService = this.typeOfServiceAll.filter((el) => el.type === this.newItem.type);
    },

    getTypesOfCargo(){
      axios.get('/employee/type-of-cargo/get').then(response => {
        if(response.status == 200){
          this.typeOfCargo = response.data
        }else{
          this.$message.error(
            'Тип груза не найдены',
            3
          );
        }
      }).catch(error => {
        if(error.response.status == 500){
          this.$message.error(
            'Ошибка сервера',
            3
          );
        }else{
          this.$message.error(
            'Тип груза не найдены',
            3
          );
        }
      }).finally(() => {

      });
    },
    getBranches(){
      axios.get('/branches').then(response => {
        if(response.status == 200){
          this.branches = response.data
        }else{
          this.$message.error(
            'Филиалов не найдено',
            3
          );
        }
      }).catch(error => {
        if(error.response.status == 500){
          this.$message.error(
            'Ошибка сервера',
            3
          );
        }else{
          this.$message.error(
            'Филиалов не найдено',
            3
          );
        }
      });
    },
    getServiceTypes(){
      axios.get('/employee/type-of-service/get').then(response => {
        if(response.status == 200){
          this.typeOfServiceAll = response.data;
          this.typeOfService = this.typeOfServiceAll.filter((el) => el.type === this.newItem.type);
        }else{
          this.$message.error(
            'Тип услуги не найдено',
            3
          );
        }
      }).catch(error => {
        if(error.response.status == 500){
          this.$message.error(
            'Ошибка сервера',
            3
          );
        }else{
          this.$message.error(
            'Тип услуги не найдено',
            3
          );
        }
      }).finally(() => {
        this.load = false;
      });
    },
    getRegions(){
        axios.post('/international/regions/autocomplete/search', this.dataFilter, {params: {query: ''}}).then(response => {
          if(response.status == 200){
            this.regions = response.data

          }else if(response.status == 204){
            this.$message.error(
                  'Нет соответствующих регионов',
                  3,
            );
          }
        }).catch(error => {
          if(error.response.status == 204){
            this.$message.error(
                  'Нет соответствующих регионов',
                  3,
            );
          }else if(error.response.status == 500){
            this.$message.error(
                  'Ошибка сервера',
                  3,
            );
          }
        })
    },
    getCities(){
        axios.post('/international/cities/autocomplete/search', this.dataFilter, {params: {query: ''}}).then(response => {
          if(response.status == 200){
            this.cities = response.data
          }else if(response.status == 204){
            this.$message.error(
                  'Нет соответствующих городов',
                  3,
            );
          }
        }).catch(error => {
          if(error.response.status == 204){
            this.$message.error(
                  'Нет соответствующих городов',
                  3,
            );
          }else if(error.response.status == 500){
            this.$message.error(
                  'Ошибка сервера',
                  3,
            );
          }
        });
    },
    getClients(){
      axios.get('/employee/clients').then(response => {
        if(response.status == 200){
          this.clients = response.data
        }else{
          this.$message.error(
            'Клиенты не найдено',
            3
          );
        }
      }).catch(error => {
        if(error.response.status == 500){
          this.$message.error(
            'Ошибка сервера',
            3
          );
        }else{
          this.$message.error(
            'Клиенты не найдено',
            3
          );
        }
      }).finally(() => {
        this.load = false;
      });
    },
    addPriceData(){
      if(this.newItem.price_type == 'linear'){
        let linearPrice = {
              "basic_price": 1,
              "included_weight": 1,
              "included_km": 1,
              "price_per_weight": 1,
              "weight": 1,
              "price_per_km": 1,
              "basic_price_per_return": 0,
              "price_per_return_per_weight": 1
        }
        this.addPrice = false;
        this.newItem.prices.push(linearPrice)
      }else{
        let unlinearPrice = {
          "basic_price": 1,
          "mode": "only_kg",

          "weight_from": 1,
          "weight_to": 1,
          "km_from": 1,
          "km_to": 1,

          "included_weight": 1,
          "included_km": 1,

          "price_per_weight": 1,
          "weight": 1,
          "price_per_km": 1,
          "basic_price_per_return": 1,
          "price_per_return_per_weight": 1
        }
        this.newItem.unlinear_price.push(unlinearPrice)
      }

    },
    clearLinearPrice(index){
      this.newItem.prices.splice(index, 1);
      this.addPrice = true;
    },
    clearUnlinearPrice(index){
      this.newItem.unlinear_price.splice(index, 1);
    },
    changePriceType(){
      if(this.newItem.price_type == 'linear'){
        this.linearPrice = true;
        this.unlinearPrice = false;
        this.addPriceButton;
      }else{
        this.linearPrice   = false;
        this.addPrice = true;
        this.unlinearPrice = true;
        this.addPriceButton;
      }
    },

    boolRuleAndOr(rules){
      if(rules[0].boolAndOr == 'and'){
        rules[0].boolAndOr = 'or'
      }else{
        rules[0].boolAndOr = 'and'
      }
    },
    addCondition(rules){
      let conditionArrayFull = {conditions: [{condition_name: ''}]}
      let conditionArray = [{condition_name: ''}];
      let condition = {condition_name: ''};
      if(rules[1]){
        if(rules[1].conditions){
          rules[1].conditions.push(condition);
          this.$forceUpdate()
        }else{
          rules[1].conditions = conditionArray;
          this.$forceUpdate()
        }
      }else{
        rules.push(conditionArrayFull);
        this.$forceUpdate();
      }
    },
    addRule(rules){
      let rule = {
        rules: [{
          showRules: false,
          addRulesItem: true,
          addConditionsItem: false,
          conditionsBool: false,
          boolAndOr: 'and'
        }]
      };
      rules.push(rule);
      this.$forceUpdate();
    },
    changeItem(rules){
      rules[0].addRulesItem = !rules[0].addRulesItem;
      rules[0].addConditionsItem = !rules[0].addConditionsItem;
    },
    borderLeftYes(rules, index){
      if(rules[index+1]){
        return 1
      }else{
        return 0
      }
    },
    getRule(rules){
      rules[0].showRules = !rules[0].showRules
      return rules[0].showRules;
    },
    hasConditions(rules){
      if(rules.length>=2){
        if(rules[1].conditions){
          return 1;
        }else{
          return 0;
        }
      }
    },
    getChildRules(rule){
      if(rule){
        return 1;
      }
    },
    functionAddRules(rules, index){
      rules[index].showRules = !rules[index].showRules
      return rules[index].showRules;
    },
    boolAddRules(rules){
      return rules[0].showRules
    },
    changeBranchOffice(value){
      if(!value){
        this.newItem.branchoffice_id = null
      }
    },
    changeClientType(value){
      if(!value){
        this.newItem.client_type = null
      }
    }
  },
  computed: {
    addPriceButton(){
      if(this.newItem.prices.length && this.newItem.price_type == 'linear'){
        this.addPrice = false;
      }else{
        this.addPrice = true;
      }
    },
  }
}
</script>

<style>
  .rules-content{
    user-select: none
  }
  .border-left-yes{
    border-left: 2px solid #333;
    margin-left: 20px;
    padding-bottom: 30px;
    display: flex;
    align-items: center;
  }
  .border-left-yes-for-rules{
    border-left: 2px solid #333;
  }
  .rules{
    width: 200px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    background: #fff;
    height: 60px;
    padding: 10px;
    border-radius: 1px;
    font-size: 16px;
    cursor: pointer;
  }
  .rules-add-conditions{
    display: flex;
    align-items: center;
    justify-content: flex-start;
    background: #fff;
    height: 60px;
    width: 100%;
    padding: 10px;
    border-radius: 1px;
    font-size: 16px;
    margin-top: 10px
  }
  .conditions{
    display: flex;
    align-items: center;
    justify-content: flex-start;
    flex-wrap: wrap;
    background: #fff;
    padding: 10px;
    border-radius: 1px;
    font-size: 16px;
    position: relative;
  }
  .border-with-before{
    position: relative;
  }
  .border-with-before::before{
    top: 0 !important;
    left: -10px !important;
    width: 10px !important;
    bottom: 50% !important;
    border: 2px solid #424242 !important;
    content: "";
    position: absolute !important;
    border-top: none !important;
    border-right: none !important;
  }
  .pos-rel{
    position: relative;
  }
  .conditions div{
    width: 200px !important;
  }
  .conditions div.width-100{
      width: 100% !important;
      margin: 10px 5px;
  }
  .d-100{
    width: 100px;
    display: flex;
    justify-content: center;
    cursor: pointer;
    height: 40px;
    align-items: center;
    border-radius: 3px;
  }
  .d-h{
    transition: all 0.5s ease;
  }
  .d-h:hover{
    background: #efefef;
  }
  .d-150{
    width: 150px;
    display: flex;
    justify-content: center;
    cursor: pointer;
    height: 40px;
    align-items: center;
    border-radius: 3px;
  }
  .d-200{
    width: 200px;
    display: flex;
    justify-content: center;
    cursor: pointer;
    height: 40px;
    align-items: center;
    border-radius: 3px;
  }
  .div-margin-5 div{
    margin: 0 3px !important;
  }
  .box-shadow{
    box-shadow: rgba(0, 0, 0, 0.12) 0px 1px 6px, rgba(0, 0, 0, 0.12) 0px 1px 4px;
  }
  .trash-conditions{
    background: #fff;
    padding: 5px;
    margin-left: 20px;
    border-radius: 50%;
    cursor: pointer;
  }
  .ant-form-item{
    margin-bottom: 0 !important;
  }
</style>
